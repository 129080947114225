import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import "typeface-source-sans-pro";
import footerStyles from '../styles/footer.module.scss';

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `)
    const year = new Date().getFullYear();

    return(
        <footer className={footerStyles.footer}>
            <p className={footerStyles.footerText}>{data.site.siteMetadata.author} © {year}</p>
        </footer>
    )
}

export default Footer