import React, { Fragment } from 'react';

const getViewBox = name => {
    switch (name) {
      case "github":
        return "0 0 480 512";
      case "linkedIn":
        return "0 0 448 512";
      case "instagram":
        return "0 0 48 48";
      case "swagger":
        return "0 0 256 256";
      default:
        return "0 0 48 48";
    }
  };

const getPath = (name, props) => {
switch (name) {
    case "github":
    return (
        <path
        {...props}
        d="M 186.1 328.7 c 0 20.9 -10.9 55.1 -36.7 55.1 s -36.7 -34.2 -36.7 -55.1 s 10.9 -55.1 36.7 -55.1 s 36.7 34.2 36.7 55.1 Z M 480 278.2 c 0 31.9 -3.2 65.7 -17.5 95 c -37.9 76.6 -142.1 74.8 -216.7 74.8 c -75.8 0 -186.2 2.7 -225.6 -74.8 c -14.6 -29 -20.2 -63.1 -20.2 -95 c 0 -41.9 13.9 -81.5 41.5 -113.6 c -5.2 -15.8 -7.7 -32.4 -7.7 -48.8 c 0 -21.5 4.9 -32.3 14.6 -51.8 c 45.3 0 74.3 9 108.8 36 c 29 -6.9 58.8 -10 88.7 -10 c 27 0 54.2 2.9 80.4 9.2 c 34 -26.7 63 -35.2 107.8 -35.2 c 9.8 19.5 14.6 30.3 14.6 51.8 c 0 16.4 -2.6 32.7 -7.7 48.2 c 27.5 32.4 39 72.3 39 114.2 Z m -64.3 50.5 c 0 -43.9 -26.7 -82.6 -73.5 -82.6 c -18.9 0 -37 3.4 -56 6 c -14.9 2.3 -29.8 3.2 -45.1 3.2 c -15.2 0 -30.1 -0.9 -45.1 -3.2 c -18.7 -2.6 -37 -6 -56 -6 c -46.8 0 -73.5 38.7 -73.5 82.6 c 0 87.8 80.4 101.3 150.4 101.3 h 48.2 c 70.3 0 150.6 -13.4 150.6 -101.3 Z m -82.6 -55.1 c -25.8 0 -36.7 34.2 -36.7 55.1 s 10.9 55.1 36.7 55.1 s 36.7 -34.2 36.7 -55.1 s -10.9 -55.1 -36.7 -55.1 Z"
        />
    );
    case "linkedIn":
    return (
        <path
        {...props}
        d="M 100.28 448 H 7.4 V 148.9 h 92.88 Z M 53.79 108.1 C 24.09 108.1 0 83.5 0 53.8 a 53.79 53.79 0 0 1 107.58 0 c 0 29.7 -24.1 54.3 -53.79 54.3 Z M 447.9 448 h -92.68 V 302.4 c 0 -34.7 -0.7 -79.2 -48.29 -79.2 c -48.29 0 -55.69 37.7 -55.69 76.7 V 448 h -92.78 V 148.9 h 89.08 v 40.8 h 1.3 c 12.4 -23.5 42.69 -48.3 87.88 -48.3 c 94 0 111.28 61.9 111.28 142.3 V 448 Z"
        />
    );
    case "instagram":
    return (
        <path
        {...props}
        d="M 31.2 12.3 H 16.8 c -2.5 0 -4.5 2 -4.5 4.5 v 4.8 v 9.6 c 0 2.5 2 4.5 4.5 4.5 h 14.4 c 2.5 0 4.5 -2 4.5 -4.5 v -9.6 v -4.8 C 35.7 14.3 33.7 12.3 31.2 12.3 Z M 32.5 15 l 0.5 0 v 0.5 V 19 l -4 0 l 0 -4 L 32.5 15 Z M 20.7 21.6 c 0.7 -1 2 -1.7 3.3 -1.7 s 2.6 0.7 3.3 1.7 c 0.5 0.7 0.8 1.5 0.8 2.4 c 0 2.3 -1.9 4.1 -4.1 4.1 s -4.1 -1.8 -4.1 -4.1 C 19.9 23.1 20.2 22.3 20.7 21.6 Z M 33.4 31.2 c 0 1.2 -1 2.2 -2.2 2.2 H 16.8 c -1.2 0 -2.2 -1 -2.2 -2.2 v -9.6 h 3.5 c -0.3 0.7 -0.5 1.6 -0.5 2.4 c 0 3.5 2.9 6.4 6.4 6.4 c 3.5 0 6.4 -2.9 6.4 -6.4 c 0 -0.8 -0.2 -1.7 -0.5 -2.4 h 3.5 V 31.2 Z"        />
    );
    case "swagger":
      return (
        <Fragment>
          <path {...props} d="M127.06 255.997C58.85 255.527-.457 198.918.003 126.998C.445 57.796 57.952-.469 129.11.003c69.157.46 127.503 57.864 126.885 128.191c.573 69.69-58.06 128.295-128.935 127.803zm0 0C58.85 255.527-.457 198.918.003 126.998C.445 57.796 57.952-.469 129.11.003c69.157.46 127.503 57.864 126.885 128.191c.573 69.69-58.06 128.295-128.935 127.803z" fill="#FFF"/>
          <path {...props} d="M127.185 238.997C68.032 238.59 16.604 189.5 17.002 127.131c.384-60.012 50.253-110.54 111.961-110.129c59.972.399 110.569 50.18 110.033 111.167c.496 60.433-50.349 111.255-111.811 110.828zm0 0C68.032 238.59 16.604 189.5 17.002 127.131c.384-60.012 50.253-110.54 111.961-110.129c59.972.399 110.569 50.18 110.033 111.167c.496 60.433-50.349 111.255-111.811 110.828z"/>
          <path {...props} d="M169.327 127.956c-.284 5.29-4.906 9.683-9.46 8.917h-.069a9.133 9.133 0 0 1-9.145-9.124a9.276 9.276 0 0 1 9.427-8.96c5.045.025 9.52 4.288 9.247 9.167zM88.201 179.22c1.902.056 3.806.015 6 .015v13.786c-13.635 2.305-24.866-1.566-27.622-13.091a76.501 76.501 0 0 1-1.736-12.886c-.293-4.592.213-9.235-.137-13.819c-.97-12.612-2.603-16.918-14.706-17.514v-15.696a22.665 22.665 0 0 1 2.632-.452c6.636-.327 9.433-2.362 10.917-8.898a74.57 74.57 0 0 0 1.194-11.122c.526-7.217.34-14.551 1.542-21.651c1.737-10.267 8.111-15.255 18.64-15.814c2.996-.16 6.001-.024 9.396-.024v14.09c-1.397.1-2.677.303-3.95.265c-8.58-.263-9.023 2.66-9.65 9.762c-.39 4.454.149 8.984-.155 13.454a107.113 107.113 0 0 1-1.781 13.245c-1.238 6.339-5.135 11.053-10.535 15.053c10.483 6.823 11.676 17.423 12.357 28.188c.366 5.784.199 11.61.786 17.366c.457 4.467 2.195 5.607 6.808 5.743zm8.836-60.43h.162a9.078 9.078 0 0 1 8.928 9.226a8.884 8.884 0 0 1-9.396 8.851a9.046 9.046 0 1 1 .306-18.078zm31.236 0c5.483-.043 9.124 3.51 9.152 8.93c.03 5.565-3.422 9.125-8.868 9.149c-5.54.024-9.186-3.48-9.216-8.867a8.671 8.671 0 0 1 8.933-9.213zm65.4-6.867c1.459 5.447 4.298 7.362 10.032 7.623c.94.044 1.875.203 3.163.346v15.692c-.697.23-1.412.4-2.138.512c-7.684.478-11.186 3.63-11.962 11.335c-.496 4.919-.455 9.892-.796 14.828a125.806 125.806 0 0 1-1.476 16.18c-1.96 9.703-8.019 14.544-18.029 15.135c-3.221.19-6.466.03-9.94.03v-14.026c1.87-.115 3.52-.275 5.174-.314c5.981-.143 8.096-2.071 8.389-8.011c.324-6.525.465-13.059.757-19.586c.423-9.433 3.006-17.861 11.795-23.745c-5.03-3.585-9.067-7.928-10.112-13.783c-1.265-7.097-1.674-14.351-2.355-21.548c-.336-3.597-.32-7.226-.671-10.821c-.379-3.88-3.045-5.224-6.577-5.31c-2.024-.05-4.055-.01-6.642-.01V62.754c16.509-2.741 27.913 2.752 28.973 18.548c.443 6.632.378 13.297.803 19.93c.186 3.608.726 7.19 1.612 10.691z" fill="#FFF"/>
        </Fragment>
      )
    default:
    return <path />;
}
};
  

const SVG = ({
    name = "",
    style = {},
    fill = "inherit",
    width = "100%",
    height = "100%",
    className= "",
    viewBox = ""
}) => (
    <svg
        style={style}
        width={width}
        height={height}
        viewBox={viewBox || getViewBox(name)}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        {getPath(name, { fill })}
    </svg>
);

export default SVG