import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby";
import SVG from './icon';
import headerStyles from '../styles/header.module.scss';

const Header = () => {    
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    author
                }
            }
        }
    `)
    
    return(
        <header className={headerStyles.header}>
            <div className={headerStyles.navbar}>
                <div className={headerStyles.bioContent}>
                    <div className={headerStyles.avatar}>
                        <img src="./images/tommyAvatar.png" alt="headshot" />
                    </div>
                    <div className={headerStyles.titleContainer}>
                            <h1>
                                <Link className={headerStyles.title} to="/">{data.site.siteMetadata.author}</Link>
                            </h1>
                            <h2 className={headerStyles.titleItem}>Full-Stack Web Developer<br/>Baltimore-DC Metro</h2>
                        <ul className={headerStyles.svgList}>
                            <li>
                                <a className={headerStyles.iconItem} target="_blank" rel="noopener noreferrer" href="https://github.com/thomasdahlberg">
                                    <SVG name="github" width={40} />
                                </a>
                            </li>
                            <li>
                                <a className={headerStyles.iconItem} target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/tommydahlberg">
                                    <SVG name="linkedIn" width={40} />
                                </a>
                            </li>
                        </ul>                        
                    </div>
                </div>
            </div>
            <nav>
                <ul className={headerStyles.navList}>
                    <li>
                        <a className={headerStyles.navItem} activeClassName ={headerStyles.activeNavItem} href="#projects">Projects</a>
                    </li>
                    <li>
                        <a className={headerStyles.navItem} activeClassName ={headerStyles.activeNavItem} href="../resume/Dahlberg_Resume.pdf" target="_blank" rel="noopener noreferrer">Resume</a>
                    </li>
                    <li>
                        <a className={headerStyles.navItem} activeClassName ={headerStyles.activeNavItem} href="#email">Contact</a>
                    </li>
                </ul>
            </nav>

        </header>
    )
}

export default Header