import React from 'react';
import "typeface-aleo";
import Header from '../components/header';
import Footer from '../components/footer';
import layoutStyles from '../styles/layout.module.scss';
import '../styles/index.scss';

const Layout = (props) => {
    return(
        <div className={layoutStyles.container}>
            <div className={layoutStyles.content}>
                <Header />
                <div className={layoutStyles.innerContent}>
                    {props.children}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Layout